import api from '@/fetch/api'
import CommentHelper from '@/helpers/comment.helper';
import utils from '@/utils';

const state = {
  notifications: [],
  unreadCount: 0
}

const getters = {
  notifications: state => state.notifications,
  formattedNotifications: state => {
    return state.notifications.map(notification => {
      notification.html = CommentHelper.format2html(notification.content) 
      return notification
    })
  }
}

const actions = {
  getNotifications({ commit, state }) {
    return new Promise((resolve, reject) => {
      let notifications = []
      utils.getDataFromSw('notifications', data => {
        notifications = data ? data.notifications : [];
        commit('setNotifications', notifications)
        resolve(notifications)
      });
      api
        .get_notifications({})
        .then(res => {
          notifications = res ? res.notifications : [];
          commit('setNotifications', notifications)
          resolve(notifications)
        })
        .catch(ex => {
          reject(ex)
        })
    })
  },
  readNotification({ commit, state }, { id }) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.read_notification([id])
        commit('decrementUnreadCount');
        commit('readNotification', id);
        resolve(res);
      } 
      catch(ex) {
        reject(ex);
      }
    })
  },

  deleteAll({ commit }) {
    api.delete_all_notifications()
    commit('setUnreadCount', 0)
    commit('setNotifications', [])
  },

  setUnreadCount({ commit }, { count }) {
    commit('setUnreadCount', count)
  },

  decrementUnreadCount({commit}) {
    commit('decrementUnreadCount')
  },

  incrementUnreadCount({commit}) {
    commit('incrementUnreadCount')
  },
}

const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications
  },

  readNotification(state, id) {
    let noti = state.notifications.find(n => n.id == id)
    noti && (noti.is_read = true)
  },

  decrementUnreadCount(state) {
    state.unreadCount > 0 && state.unreadCount--;
  },

  incrementUnreadCount(state) {
    state.unreadCount++;
  },

  setUnreadCount(state, count) {
    state.unreadCount = count;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import {
  UPDATE_MAIL_LIST,
  UPDATE_MAIL_LIST_ITEMS,
  DELETE_MAIL_LIST_ITEMS,
  SET_OPEN_TABS,
  ADD_OPEN_TAB,
  REMOVE_OPEN_TAB,
  RESET_OPEN_TABS,
  SET_ACTIVE_TAB,
  SET_EXPANDED_PREVIEW_MODE
} from '../../mutation-type'
import api from 'api'
import LocalStorageConstant from '@/common/constants/local-storage.constant';
import util from "util";

const state = {
  mailList: [],
  mailNum: 0,
  pageSize: 50,
  bookmark: undefined,
  systemBookmark: undefined,
  openingTabs: JSON.parse(localStorage.getItem(LocalStorageConstant.OPEN_MAIL_TABS)) || [],
  activeTab: null,
  previewExpanded: false
};

const getters = {
  mailList: state => state.mailList,
  mailNum: state => state.mailNum,
  pageSize: state => state.pageSize,
  bookmark: state => state.bookmark,
  systemBookmark: state => state.systemBookmark,
  openingTabs: state => state.openingTabs,
  activeTab: state => state.activeTab,
  previewExpanded: state => state.previewExpanded
};

const mutations = {
  [UPDATE_MAIL_LIST](state, param) {
    state.mailList = param.emails.map(email => {
      if (!email.star) email.star = false;
      return email;
    });

    if (!param.key) return;
    if (!util.routeBasedEmails[param.key]) return;

    const emailMap = new Map();
    for (const email of param.emails.reverse()) {
      emailMap.set(email.id, email)
    }
    util.routeBasedEmails[param.key].emailMap = emailMap;
  },

  [UPDATE_MAIL_LIST_ITEMS](_, { ids, changedProps }) {
    util.updateAllCachedEmails({ ids, changedProps });
  },

  [DELETE_MAIL_LIST_ITEMS](state, { ids, changedProps, currentCacheKey, includeKeys }) {
    util.updateAllCachedEmails({ ids, changedProps, remove: true, includeKeys });
    const current = util.routeBasedEmails[currentCacheKey];
    if (!current || !current.emailMap) return;

    const currentMails = [...current.emailMap.values()];
    if (!currentMails) return;
    state.mailList = currentMails;
  },

  setStarStatus(state, param) {
    const { selected, mailIds } = param;
    mailIds.forEach(mailId => {
      let mail = state.mailList.find(m => m.id == mailId);
      if(mail)
        mail.star = selected;
    });
  },

  removeMails(state, mailIds) {
    mailIds.forEach(id => {
      const i = state.mailList.findIndex(m => m.id == id)
      i != -1 && state.mailList.splice(i, 1);
      state.mailNum = state.mailNum - 1;
    })
  },

  setUserBookmark(state, bookmark) {
    state.bookmark = bookmark;
  },
  setSystemBookmark(state, bookmark) {
    state.systemBookmark = bookmark;
  },

  [SET_OPEN_TABS]: (state, tabs) => {
    state.openingTabs = tabs;
    localStorage.setItem(LocalStorageConstant.OPEN_MAIL_TABS, JSON.stringify(tabs));
  },
  [ADD_OPEN_TAB]: (state, { tab }) => {
    const index = state.openingTabs.findIndex(t => t.name == tab.name);
    if(index == -1) {
      state.openingTabs.push(tab);
      localStorage.setItem(LocalStorageConstant.OPEN_MAIL_TABS, JSON.stringify(state.openingTabs));
    }
  },
  [REMOVE_OPEN_TAB]: (state, { tabName }) => {
    const index = state.openingTabs.findIndex(t => t.name == tabName);
    if(index != -1) {
      state.openingTabs.splice(index, 1);
      localStorage.setItem(LocalStorageConstant.OPEN_MAIL_TABS, JSON.stringify(state.openingTabs));
    }
    state.activeTab && tabName == state.activeTab.name && (state.activeTab = null);
  },
  [RESET_OPEN_TABS]: (state) => {
    state.activeTab = null;
    state.openingTabs = [];
    localStorage.setItem(LocalStorageConstant.OPEN_MAIL_TABS, JSON.stringify(state.openingTabs));
  },
  [SET_ACTIVE_TAB]: (state, tab) => {
    state.activeTab = tab;
  },
  [SET_EXPANDED_PREVIEW_MODE]: (state, status) => {
    state.previewExpanded = status;
  }
};

const actions = {
  starMail({ commit, dispatch }, param) {
    const { selected, mailIds } = param;
    commit("setStarStatus", { selected, mailIds });
    !selected 
      && state.bookmark 
      && state.bookmark.params.is_star == 'true'
      && mailIds.findIndex(id => id == state.bookmark.email_id) > -1
      && dispatch('removeUserBookmark', state.bookmark.id);
    util.sendMessageToSw({
      message: "removeEmailDetail",
      email_ids: mailIds || [],
    });
    return selected ? api.star_mail(mailIds) : api.unstar_mail(mailIds);
  },
  removeMails({ commit, dispatch }, mailIds) {
    commit("removeMails", mailIds);
    state.bookmark
      && mailIds.findIndex(id => id == state.bookmark.email_id) > -1
      && dispatch('removeUserBookmark', state.bookmark.id);
    return api.delete_emails({ email_ids: mailIds });
  },
  archive({dispatch}, ids) {
    state.bookmark 
      && ids.findIndex(id => id == state.bookmark.email_id) > -1
      && dispatch('removeUserBookmark', state.bookmark.id);
    return api
      .archive_email({
        email_ids: ids,
        archive: true
      })
      .catch(error => {
        console.log(error)
      });
  },
  unarchive({dispatch}, ids) {
    state.bookmark
      && state.bookmark.params.archived == 'true'
      && ids.findIndex(id => id == state.bookmark.email_id) > -1
      && dispatch('removeUserBookmark', state.bookmark.id);
    return api
      .archive_email({
        email_ids: ids,
        archive: false
      })
      .catch(error => {
        console.log(error)
      });
  },
  addUserBookmark({ commit }, bookmark) {
    commit('setUserBookmark', bookmark);
    return api.add_bookmark(bookmark.email_id, JSON.stringify(bookmark.params)).then(res => {
      commit('setUserBookmark', res);
    })
  },
  addSystemBookmark({dispatch}, bookmark) {
    return api.add_bookmark(bookmark.email_id, JSON.stringify(bookmark.params), true).then(res => {
      dispatch("saveLocalBookmark", res)
    })
  },
  removeUserBookmark({ commit }, id) {
    if (!id) return;
    commit('setUserBookmark', undefined);
    return api.remove_bookmark(id);
  },
  markAsSpam({commit, dispatch}, ids) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.mark_as_spam(ids);
        state.bookmark
          && state.bookmark.id
          && ids.findIndex(id => id == state.bookmark.email_id) > -1
          && dispatch('removeBookmark', state.bookmark.id);
        util.sendMessageToSw({
          message: "removeEmailDetail",
          email_ids: ids || [],
        });
        resolve(res);
      } catch(error) {
        reject(error)
      }
    })
  },
  unSpamEmails({dispatch}, ids) {
    return new Promise(async (resolve, reject)=> {
      try {
        const res = await api.unspam_emails({ ids: ids })
        state.bookmark
          && state.bookmark.id
          && state.bookmark.params.box && state.bookmark.params.box.toLowerCase() == 'spam'
          && ids.findIndex(id => id == state.bookmark.email_id) > -1
          && dispatch('removeBookmark', state.bookmark.id);
        resolve(res);
      } catch(err) {
        reject(err);
      }
    })
  },
  async getOpenTabs({commit}) {
    const res = await api.get_openedTabs();
    if (!res) return;

    const { tabs } = res;
    commit(SET_OPEN_TABS, tabs);
  },
  async lockDraft({commit}, emailId) {
    return api.lock_draft(emailId, 'active')
  },
  async unlockDraft({commit}, emailId) {
    return api.lock_draft(emailId, 'deactive')
  },
  async deleteDraft({commit, state}, draftId) {
    state.bookmark
      && state.bookmark.params.box == 'Draft'
      && draftId == state.bookmark.email_id
      && dispatch('removeBookmark', state.bookmark.id);

    return api.delete_draft_mail(draftId, {
      email_id: draftId
    })
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from "./App";
import VueRouter from 'vue-router';
import routes from './router/index'
import store from './vuex';
import VueRx from 'vue-rx'
import iView from 'iview';
import 'iview/dist/styles/iview.css';
import '../my-theme/index.less';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import locale from 'iview/src/locale/lang/en-US';
import VueLazyload from 'vue-lazyload'
import util from 'util'
import isFunction from 'lodash/isFunction';
import Cookies from 'js-cookie';
import storageConstant from '@/common/constants/local-storage.constant';

// simple websocket encapsulation
import ActionCable from 'actioncable'
import SVGSprite from "components/SVGSprite.vue";
import Footer from 'components/footer.vue';
import './utils/dateFilter';
require('font-awesome/css/font-awesome.css')
import { globalMixin } from "./mixins";

import "./registerServiceWorker";

const Cpackage = require("../package.json");
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://0ec3dfb876424186bbc23e70fb6866b1@sentry.io/1368268",
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    ignoreErrors: [/network error/i, /401/, /429/],
    release: Cpackage.version,
  });
}

window.navigator && window.navigator.registerProtocolHandler && window.navigator.registerProtocolHandler("mailto", location.origin + "/#/sendMail?mailto=%s", "ChartDesk");

Vue.component('svg-sprite', SVGSprite);
Vue.component('chartdesk-footer', Footer);

Vue.use(iView, {locale});
Vue.use(VueRouter);
Vue.use(VueRx);

const avatar = util.randomAvatar();

Vue.use(VueLazyload, {
  preLoad: 1,
  error: avatar,
  loading: avatar,
  attempt: 1,
});

Vue.mixin(globalMixin);
Vue.config.productionTip = false;


/* eslint-disable no-new */

export const router = new VueRouter({
  // mode: 'history',
  routes
});

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      console.warn(err)
      return err
    }
    console.log
    // rethrow error
    return Promise.reject(err)
  })
}

VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }
  return originalReplace.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      console.warn(err)
      return err
    }
    console.log
    // rethrow error
    return Promise.reject(err)
  })
}


let WEBSOCKET_ADDRESS;

router.beforeEach((to, from, next) => {
  let token = util.getToken();
  if (to.path === '/login') {
    localStorage.removeItem(storageConstant.TOKEN);
    localStorage.removeItem(storageConstant.MFA_CODE);
    localStorage.removeItem(storageConstant.TWO_FA_ENABLED);
    Cookies.remove(storageConstant.TOKEN)
    Cookies.remove(storageConstant.MFA_CODE)
    store.dispatch("resetUserData");
    if (Vue.prototype.$ActionCable) {
      Vue.prototype.$ActionCable.subscriptions.subscriptions.splice(0, 1);
    }
    next()
  } else if (typeof(token) !== 'string') {
    // public link for contact
    if (to.path === '/shares' 
      || to.path === '/resetPassword' 
      || to.path === '/loginSSO' 
      || to.path === '/completeInvitation' 
      || to.path.includes('/two-factor-verification')) 
    {
      next();
    } else {
      next({path: '/login'})
    }
  } else if(to.path == '/sendMail') {
    next()
  } else {
    //webSocket
    handleBaseAddress();
    const tenantID = util.getTenantID();
    let address = tenantID ? util.appendTenantID(WEBSOCKET_ADDRESS, tenantID, true) : WEBSOCKET_ADDRESS;
    if (!Vue.prototype.$ActionCable || !Vue.prototype.$ActionCable.url.match(token) || (tenantID && !Vue.prototype.$ActionCable.url.match(tenantID))) {
      let url = `${address}?Auth-Token=${token}`;
      tenantID && (url += `&Tenant-Id=${tenantID}`)
      Vue.prototype.$ActionCable = ActionCable.createConsumer(url)
    }
    if (Vue.prototype.$ActionCable.subscriptions.subscriptions.length === 0) {
      const $events = Vue.prototype.$ActionCable.connection.events
      const sub = Vue.prototype.$ActionCable.subscriptions.create({
          channel: 'NotificationsChannel'
        }, {
          connected: function () {
            console.log('connected: ', new Date());
            sub && sub.perform("get_server_datetime");
            if (!isFunction(this.connected.mail)) return
            this.connected.mail()
          },
          received: function (data) {
            switch (data.type) {
              case "ServerDateTime":
                configureServerDateTime(data.message);
                break;
              case "CopyFolder":
                store.dispatch("loadChildFolders", {
                  parentId: data.message.parent_id,
                  category: data.message.personal ? "personal" : "share",
                  copy_folder_name: data.message.name,
                  copy_done: true,
                  no_cache: true
                });
                break;
              case "Email":
                if (!isFunction(this.received.mail)) return;
                data.message.forEach((item) => {
                  this.received.mail(item);
                  util.sendMessageToSw({
                    message: "saveEmail",
                    item,
                  });
                });
                break;
              case "MarkAsRead":
                if (!isFunction(this.received.markAsRead)) return;
                const ids = data.message[0] ? data.message[0].ids : []
                this.received.markAsRead(ids);
                break;
              case "Tag":
                if (!isFunction(this.received.tag)) return;
                this.received.tag(data);
                break;
              case "Notification":
              case "Permission":
                if (!isFunction(this.received.header)) return;
                this.received.header(data);
                break;
              case "Comment":
                if (!isFunction(this.received.comment)) return;
                this.received.comment(data);
                break;
              case "LineStatus":
                if (!isFunction(this.received.lineStatus)) return;
                data.message && this.received.lineStatus(data.message);
                break;
              case "UpdateUnreadCount":
                if (!isFunction(this.received.unreadCount)) return;
                this.received.unreadCount(data.message);
                break;
              case "Assignment":
                if (!isFunction(this.received.assignment)) return;
                data.message && this.received.assignment(data.message);
                break;
              case "UpdateCloseStatus":
                if (!isFunction(this.received.mailClosed)) return;
                data.message &&
                  data.message.length > 0 &&
                  data.message.map((item) => this.received.mailClosed(item));
                break;
              case "EmailFolder":
                if (!isFunction(this.received.emailFolder)) return;
                data.message && this.received.emailFolder(data.message);
                break;
              case "CompanyTag":
                if (!isFunction(this.received.companyTag)) return;
                data.message && this.received.companyTag(data.message);
                break;
              case "UpdateRoleDisableMention":
                if(!data.message) return;
                store.commit("SET_ROLE_DISABLE_MENTION", data.message)
                break;
              default:
                break;
            }
          },
          disconnected: function () {
            console.log('GG: ', new Date());
            if (!isFunction(this.disconnected.mail)) return
            this.disconnected.mail()
          }
        },
      );
    }
    next()
  }
});

util.getDataFromSw("online", (data) => {
  store.commit("UPDATE_ONLINE_STATUS", data);
});

router.afterEach((to, from) => {
  updateDocumentTitle(to);
})

function updateDocumentTitle(to) {
  const { title } = to.meta;
  const documentTitle = typeof title === 'function' ? title(to) : title;
  document.title = documentTitle || 'ChartDesk';
}

function configureServerDateTime(timeStp) {
  window.timeGap = +new Date(timeStp) - Date.now();
  localStorage.setItem("timeGap", JSON.stringify(window.timeGap));
}

window.CDConfig = (function () {
  const config = {};

  return {
    keys() {
      return Object.keys(config);
    },
    get(key) {
      return config[key];
    },
    set(key, value) {
      config[key] = value;
    },
    delete(key) {
      delete config[key];
    },
    clear() {
      Object.keys(config).forEach(key => delete config[key]);
    }
  }
})()

window.timeGap = JSON.parse(localStorage.getItem("timeGap"));

function handleBaseAddress() {
  switch (window.location.hostname) {
    case "web.chartdesk.cn":
    case "qd.chartdesk.cn":
      WEBSOCKET_ADDRESS = "wss://qdapi.chartdesk.cn/cable";
      break;
    case "web.chartdesk.pro":
    case "sg.chartdesk.pro":
    case "beta.chartdesk.pro":
      WEBSOCKET_ADDRESS = "wss://prod-api.chartdesk.net/cable";
      break;
    case "web.chartdesk.de":
    case "beta.chartdesk.de":
      WEBSOCKET_ADDRESS = "wss://prod-api.chartdesk.de/cable";
      break;
    case "staging.chartdesk.uk":
      WEBSOCKET_ADDRESS = "wss://staging-api.chartdesk.uk/cable";
      break;
    default:
      WEBSOCKET_ADDRESS = "wss://staging-api.chartdesk.net/cable";
      break;
  }
}

util.registerGoogleAnaytic();

new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {App},
});

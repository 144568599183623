<template>
  <svg
    :viewBox="viewbox ? viewbox : `0 0 ${width} ${height}`"
    xmlns="http://www.w3.org/2000/svg"
    x="10"
    y="10"
    :width="width"
    :height="height"
    v-html="iconHtml"
    @click="onClick"
  />
</template>
<script>
import svgSprite from "@/utils/svg_sprite";

export default {
  props: {
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    viewbox: String,
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    iconHtml() {
      return svgSprite[this.id];
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
};
</script>
// format time to various format
// use to be filtered
// exp. Array.filter(timeFilter)
/*
 @param:
 @time: element of Array
 @index: index of Array
 @sign: Y-year D-day M-minute
 */

const validators = {
  email: new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
  url: new RegExp(/^(https?|ftp|rmtp|mms):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i),
  text: new RegExp(/^[a-zA-Z]+$/),
  digits: new RegExp(/^[\d() \.\:\-\+#]+$/),
  isodate: new RegExp(/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/),
  roleName: new RegExp(/^[A-Za-z0-9_ .-]{3,50}$/)
}

const timeFilter = function () {

  let element = arguments[0];
  let index = arguments[1];
  let sign = this.sign;
  let name = this.name;

  if (!name || !sign) {
    console.log('param should not be empty');
    return element;
  }

  // for minute
  if (sign === 'M') {
    let starter = element[name].indexOf("T");
    let ender = element[name].indexOf(".");
    if (starter !== -1 || ender !== -1) {
      element[name] = element[name].slice(starter + 1, ender);
    }
  }

  return element;
};

const formatTime = function (time) {
  let temptime = new Date(time);
  let month = temptime.getMonth();
  let Data = temptime.getDate();
  let minutes = temptime.getMinutes();
  month = month < 10 ? "0" + (month + 1) : (month + 1);
  minutes = minutes < 10 ? '0'+minutes : minutes;
  Data = Data< 10 ? "0" + Data : Data;
  function formatDay(day) {
    const week = {
      0: 'Mon',
      1: 'Tue',
      2: 'Wed',
      3: 'Thu',
      4: 'Fri',
      5: 'Sat',
      6: 'Sun',
    }
    return week[day];
  }

  function formatMonth(month) {
    const monthes = {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'Jun',
      6: 'Jul',
      7: 'Aug',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec',
    }
    return monthes[month];
  }

  return {
    year: temptime.getFullYear().toString(),
    month: month.toString(),
    date: Data.toString(),
    hour: temptime.getHours().toString(),
    minute: minutes.toString(),
    second: temptime.getSeconds().toString(),
    day: temptime.getDay().toString(),
    dayabbr: formatDay(temptime.getDay()),
    monthabbr: formatMonth(temptime.getMonth()),
  }
}

const toUTC = (date) => {
  return new Date(date).toISOString().replace('Z', '');
}
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default {
  timeFilter,
  validators,
  formatTime,
  capitalize,
  toUTC
}

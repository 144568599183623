import filter from './filter.js';
import store from 'store'
import Cookies from 'js-cookie'
import { Message } from 'iview';
import storageConstant from '@/common/constants/local-storage.constant';
import appConstant from '@/common/constants/app.constant';
import totp from "./totp-generator";
import debounce from "lodash/debounce";
const md5 = require('md5');

function formatDay(day){
    const week = {
        0: 'Mon',
        1: 'Tue',
        2: 'Wed',
        3: 'Thu',
        4: 'Fri',
        5: 'Sat',
        6: 'Sun',
    }
    return week[day];
}

function formatMonth(month){
    const monthes = {
        0: 'Jan',
        1: 'Feb',
        2: 'Mar',
        3: 'Apr',
        4: 'May',
        5: 'Jun',
        6: 'Jul',
        7: 'Aug',
        8: 'Sep',
        9: 'Oct',
        10: 'Nov',
        11: 'Dec',
    }
    return monthes[month];
}
var chatTime= function(dateable) {
  var date = new Date(dateable)
  var nowdate = new Date();
  var diffmilisec = nowdate - date;
  if(diffmilisec < 1000*60*60*12) {
    return time_12(dateable);
  } else {
    return relativeDay(dateable) + ', ' + time_12(dateable);
  }
}
var relativeTime = function(dateable) {
    var date = new Date(dateable)
    var nowdate = new Date();
    var diffmilisec = nowdate - date;

    if (diffmilisec < 0) {
        var randomSeconds = Math.floor(Math.random() * 59)
        return randomSeconds + ' second(s) ago'
    } else if (diffmilisec < 1000*60*60*12) {
        if(diffmilisec < 1000*60) {
            return parseInt(diffmilisec/1000) + ' second(s) ago'
        } else if(diffmilisec < 1000*60*60) {
            return parseInt(diffmilisec/1000/60) + ' minute(s) ago'
        } else {
            return parseInt(diffmilisec/1000/60/60) + ' hour(s) ago'
        }
    } else {
        return relativeDay(dateable) + ', ' + time_12(dateable);
    }
}

// return the relative Day or absolutely day if before 'Yesterday'
var relativeDay = function(dateable) {
    var date = new Date(dateable);
    var nowdate = new Date();
    var yesterday = new Date((new Date()).getTime()-1000*60*60*24)

    if(new Date(date).setHours(0, 0, 0, 0) === new Date(nowdate).setHours(0, 0, 0, 0)){
        return 'Today';
    } else if(new Date(date).setHours(0, 0, 0, 0) === new Date(yesterday).setHours(0, 0, 0, 0)) {
        return 'Yesterday';
    } else {
        return `${date.getDate()} ${formatMonth(date.getMonth())} ${date.getFullYear()}`
    }
}
var time_12 = function(dateable) {
    var date = new Date(dateable);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ampm;
    return strTime;
}
var relativeDayToDate = function(relativeDay) {
    var splited = relativeDay.split(', ')
    var day;
    switch(splited[0]) {
        case 'Today':
            day = `${(new Date()).getDate()} ${formatMonth((new Date()).getMonth())} ${(new Date()).getFullYear()}`;
            break;
        case 'Yesterday':
            day = `${new Date((new Date()).getTime()-1000*60*60*24).getDate()} ${formatMonth(new Date((new Date()).getTime()-1000*60*60*24).getMonth())} ${new Date((new Date()).getTime()-1000*60*60*24).getFullYear()}`;
            break;
        default:
            day = splited[0];
    }
    var time;
    var regtime = /(\d+):(\d+)/.exec(splited[1])
    if(/pm/.exec(splited[1])) {
        time = parseInt(regtime[1]) + 12 + ':' + regtime[2];
    } else {
        time = regtime[1] + ':' + regtime[2];
    }
    return new Date(`${day}, ${time}`)
}

// set and get localStorage with expiration

var storeWithExpiration = {
    set: function(key, val, exp){
        // unit of exp is ms
        store.set(key, {
            val: val,
            exp: exp,
            time: new Date().getTime()
        });
    },
    get: function(key){
        const info = store.get(key)
        if (!info) return null;
        const val = info.val;

        if(info.exp == 0){
            return val
        }else if (new Date().getTime() - info.time > info.exp){
          console.log('expired: ', new Date().getTime(), info.time,  info.exp)
            return null;
        }

        return val
    }
}


var throttle = function(fn, delay){
    var timer = null;
    return function(){
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function(){
            fn.apply(context, args);
        }, delay);
    };
 };


var randomAvatar = function(){
    var index = Math.floor(Math.random() * 12 + 1)
    return `/static/img/avatar-${index}.png`
}

//根据id给定头像
var userAvatar = function(id){
    var index = id % 12 + 1
    return `/static/img/avatar-${index}.png`
}

var getToken = function(){
    const remember = storeWithExpiration.get(storageConstant.REMEMBER)
    return remember ? storeWithExpiration.get(storageConstant.TOKEN) : Cookies.get(storageConstant.TOKEN);
}

var getMfaCode = function(){
  const remember = storeWithExpiration.get(storageConstant.REMEMBER);
  return remember ? storeWithExpiration.get(storageConstant.MFA_CODE) : Cookies.get(storageConstant.MFA_CODE);
}

var reSetAuth = function(){
    const remember = storeWithExpiration.get(storageConstant.REMEMBER)

    if (remember === true) {
        let currentToken = storeWithExpiration.get(storageConstant.TOKEN)
        storeWithExpiration.set(storageConstant.TOKEN, currentToken, 432000000);
    }
}

const updateUI = {};
const getDataFromSw = (key, callback) => {
    updateUI[key] = callback;
}
navigator.serviceWorker && navigator.serviceWorker.addEventListener(
  'message',
  ({data}) => {
    const payload = data.payload;
    switch (data.action) {
      case "request":
        for (let key in payload) {
          if (updateUI[key]) {
            if (
              payload[key].status &&
              payload[key].status === 403 &&
              payload[key].hasOfflineData
            ) {
              Message.error(
                "You don't have permission to perform this action"
              );
              window.location.hash = "#/mails";
              return;
            }
            if (
              payload[key].status &&
              payload[key].status >= 400 &&
              payload[key].hasOfflineData
            ) {
              Message.error("Network error!");
              return;
            }
            if (!payload[key].status) {
              updateUI[key](payload[key]);
              delete updateUI[key];
            }
          }
        }
        break;
      case "closeWindow":
        const saveDraft = data.payload;
        if (saveDraft && functionBus.saveAsDraft) {
          functionBus.saveAsDraft("close");
          delete functionBus.saveAsDraft;
          return;
        }
        if (!saveDraft && functionBus.discardSaveAsDraft) {
          functionBus.discardSaveAsDraft();
          return;
        }
        window.close();
        break;
      default:
        if (updateUI[data.action]) updateUI[data.action](payload);
        break;
    }
  }
);

const sendMessageToSw = (msg, callback) => {
    const msg_chan = new MessageChannel();
    if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage(msg, [msg_chan.port2]);
        updateUI[msg.message] = callback;
    }
}

const filterTitle = (subject) => {
    if (!subject) return "";
    let result;
    ["Re: ", "RE: ", "Fw: ", "FW: ", "Fwd: ", "FWD: "].forEach(e => {
        if (subject.startsWith(e)) {
            result = subject.replace(e, "");
        }
    })
    if (result) {
        return result;
    } else {
        return subject;
    } 
}

const removeSearchHtml = html => {
    if (!html) return "";
    return html.replace(/<\/?span.*?>/g, "")
               .replace(/&gt;/gi, ">")
               .replace(/&lt;/gi, "<")
}

const escapeHtmlTags = html => {
    if (!html) return "";
    return html
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/\\&/g, "&amp;")
      .replace(/\\"/g, "&quot;")
      .replace(/\\'/g, "&apos;");
}

const sanitizeHtml = html => {
  if (!html) return "";
  let element = document.createElement("div");
  element.innerHTML = html;
  return element.innerText;
}

const plainTextToHtml = text => {
  if (/\t/.test(text)) {
    return `<pre>${text}</pre>`;
  }

  return text
    .replace(/\r\n/g, "<br>")
    .replace(/\r/g, "<br>")
    .replace(/\n/g, "<br>");
}

const formatText = html => {
    if (!html) return "";
    let element = document.createElement("div");
    element.innerHTML = html;
    let textFormatted = getText(element);
    textFormatted = textFormatted.replace(/<[^>]+>/gi, "");
    return textFormatted.split("||br||").join("<br>");
}

const getText = n => {
    var rv = "";
    if (n.nodeType == 3) {
    rv = n.nodeValue;
    } else {
    if (n.childNodes && n.childNodes.length > 0) {
        for (var i = 0; i < n.childNodes.length; i++) {
        rv += getText(n.childNodes[i]);
        }
    }
    var d = getComputedStyle(n).getPropertyValue("display");
    if (
        d.match(/^block/) ||
        d.match(/list/) ||
        n.tagName === "BR" ||
        n.tagName === "TR" ||
        n.tagName === "DIV" ||
        n.tagName === "P"
    ) {
        rv = rv + "||br||";
    }
    if (n.tagName === "UL" || n.tagName === "OL") {
        rv = "||br||" + rv;
    }
    }
    rv = rv.replace(/<[^>]+>/gi, "");
    return rv;
}

const filterReaders = readers => {
    const filteredNames = [];
    const filteredReaders = [];

    readers.forEach(reader => {
    if (!filteredNames.includes(reader.name)) {
        filteredReaders.push(reader);
        filteredNames.push(reader.name);
    }
    })

    return filteredReaders;
}
const highlightSearchKey = (searchKey, source) => {
    if (!(searchKey && source)) return source;
    const regx = new RegExp(searchKey, "gi");
    return source.replace(regx, r => {
        return r ? `<mark>${r}</mark>` : "";
    })
}

const isNullOrUndefined = (obj) => {
    return obj == undefined || obj == null;
}

const deleteCharater = (el, callback) => {
  el.addEventListener('keydown', function(e) {
    if(e.key == 'Delete') {
      const text = e.target.value;
      const cursorPosition = e.target.selectionStart;
      const isSelecting = e.target.selectionStart != e.target.selectionEnd;
      const newText = isSelecting 
        ? text.substring(0, cursorPosition) + text.substring(e.target.selectionEnd, text.length)
        : text.substring(0, cursorPosition) + text.substring(cursorPosition + 1, text.length)
      e.target.value = newText;
      e.target.selectionStart = e.target.selectionEnd = cursorPosition;
      callback && callback(newText);
    }
  })
}

const scrollToLoadMore = (el, options) => {
  el.addEventListener('scroll', debounce(function(e) {
    const scrollHeight = e.target.scrollHeight;
    const clientHeight = e.target.clientHeight;
    const scrollTop = e.target.scrollTop;

    !options && (options = {
      bottomDistance: 0,
      topDistance: 0
    })

    const atTop = scrollTop <= options.topDistance;
    atTop && options.scrollToTopCallback && options.scrollToTopCallback();

    const atBottom = scrollHeight != clientHeight && (scrollHeight - scrollTop - clientHeight <= options.bottomDistance);
    atBottom && options.scrollToBottomCallback && options.scrollToBottomCallback();
  }, 300))
}

const isEmptyObject = (obj) => {
  return Object.entries(obj).length == 0 && obj.constructor == Object;
}

const getUserId = () => {
  const user = storeWithExpiration.get(storageConstant.USER);
  return user && user.user && user.user.id;
}

const is2FAEnabled = () => {
  return storeWithExpiration.get(storageConstant.TWO_FA_ENABLED);
}

const getTotpToken = () => {
  const userId = getUserId();
  const mfaBinaryCode = getMfaCode();
  const mfaCode = mfaBinaryCode && !isEmptyObject(mfaBinaryCode) && atob(mfaBinaryCode);
  const totpToken = mfaCode && mfaCode != '' && totp(mfaCode);
  const totpTokenMD5 = totpToken && totpToken != '' && md5(`${totpToken}_${userId}`);
  return totpTokenMD5;
}

const functionBus = {};
const routeBasedEmails = {};
const routeBasedEmailKeys = [];

const updateAllCachedEmails = ({ ids, changedProps, remove, includeKeys}) => {
  Object.keys(routeBasedEmails).forEach(key => {
    if (includeKeys && !includeKeys.includes(key)) return;
    if (!ids || !Array.isArray(ids)) return;

    const emailMap = routeBasedEmails[key].emailMap;

    ids.forEach(id => {
      if (emailMap.has(id)) {
        if (!remove && changedProps) {
          let email = emailMap.get(id);
          Object.assign(email, changedProps(email)); // Update UI automatically
          emailMap.set(id, email);
          return;
        }
        if (remove) {
          emailMap.delete(id);
          changedProps && Object.assign(routeBasedEmails[key], changedProps(routeBasedEmails[key]));
          return;
        }
      }
    })
  })
}

const resetCachedEmails = (pattern) => {
  const keys = Object.keys(routeBasedEmails).filter(key => key.includes(pattern));
  keys.forEach(key => {
    delete routeBasedEmails[key]
  })
}

const equalListElements = (lArray, rArray) => {
  if(lArray.length != rArray.length) 
    return false;

  for(let i = 0; i < lArray.length; i++) {
    if(lArray[i] != rArray[i])
      return false;
  }

  return true;
}

const removeLastElement = (array) => {
  array.pop();
  return array;
}

const openWindow = (path) => {
  let options = localStorage.getItem(storageConstant.WINDOW_SIZE);
  options = options && JSON.parse(options);
  const width = options && options.width ? options.width : 1100;
  const height = options && options.height ? options.height : 700;
  const left = options && !isNaN(options.left) ? options.left : (screen.width - width) / 2;
  const top = options && !isNaN(options.top) ? options.top : (screen.height - height) / 2;

  const params = [
    "height=" + height,
    "width=" + width,
    "location=no",
    "top=" + top,
    "left=" + left,
    "fullscreen=no"
  ].join(",");
  const url = window.location.origin + "/#/" + path;
  const popup = window.open(url, "_blank", params);
  popup && popup.moveTo(left, top);

  return popup;
}

const storeWindowSize = (targetWindow) => {
  localStorage.setItem(storageConstant.WINDOW_SIZE, JSON.stringify({
    width: targetWindow.outerWidth,
    height: targetWindow.outerHeight,
    top: targetWindow.screenTop,
    left: targetWindow.screenLeft
  }))
}

const selectText = (containerEl) => {
  if (document.selection) { // IE
      var range = document.body.createTextRange();
      range.moveToElementText(containerEl);
      range.select();
  } else if (window.getSelection) {
      var range = document.createRange();
      range.selectNode(containerEl);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
  }
}

const getInitialName = (fullName, maximumLetters=2) => {
  if(!fullName) 
    return "-";

  const words = fullName.split(' ');
  if(!words || words.length == 0)
    return "-";

  if(words.length == 1)
    return words[0].substr(0, maximumLetters);

  let initialName = "";
  for(let word of words) {
    word && (initialName += word.trim().substr(0, 1));
    if(initialName.length == maximumLetters)
      break;
  }
  return initialName;
}

const changeTheme = (themeName) => {
  const themes = appConstant.themeNames;
  const themeIds = Object.keys(themes);
  if(themeIds.findIndex(id => themes[id] == themeName) == -1)
    themeName = appConstant.themeNames.ORIGINAL;

  let htmlElement = document.documentElement;
  htmlElement.setAttribute('theme', themeName);
  
  let primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
  const metaTag = document.querySelector('meta[name="theme-color"]');
  metaTag && metaTag.setAttribute('content', primaryColor || '#F6F6F6')
}

const convertHexToRGBA = (hex, opacity) => {
  hex = hex.replace('#','');
  let r = parseInt(hex.substring(0,2), 16);
  let g = parseInt(hex.substring(2,4), 16);
  let b = parseInt(hex.substring(4,6), 16);

  let result = 'rgba('+r+','+g+','+b+','+ opacity +')';
  return result;
}

const compare2Object = (obj, source, exceptProperties=[]) => {
  if((!obj && source) || (obj && !source)) {
    return false;
  }
  if(!obj && !source)
    return true;
  let keys = Object.keys(obj).filter(k => exceptProperties.findIndex(p => p == k) == -1);
  let sourceKeys = Object.keys(source).filter(k => exceptProperties.findIndex(p => p == k) == -1);
  if(keys.length != sourceKeys.length)
    return false;
  for(let k of keys) {
    if(!source.hasOwnProperty(k)
      || typeof(source[k]) != typeof(obj[k])
      || typeof(obj[k]) !== 'object' && source[k] != obj[k]
      || typeof(obj[k]) == 'object' && !compare2Object(obj[k], source[k], exceptProperties))
      return false;
  }
  return true;
}

const getTenantID = () => {
  const user = storeWithExpiration.get(storageConstant.USER);
  return user && user.company && user.company.tenant_id;
}

const appendTenantID = (baseURL, tenant_id, isWebsocket) => {
  let protocol = '';
  isWebsocket && (protocol = baseURL.includes('wss://') && 'wss://' || 'ws://');
  !isWebsocket && (protocol = baseURL.includes('https://') && 'https://' || 'http://');
  baseURL = !baseURL.includes(protocol) && protocol + baseURL || baseURL;
  const tenantId = tenant_id || getTenantID();
  return tenantId
    && !baseURL.includes(`${protocol}${tenantId}.`) 
    && baseURL.replace(protocol, `${protocol}${tenantId}.`) || baseURL;
}

const distinctArray = (array, key) => {
  if(!array || array.length == 0)
    return array;
  return array.filter((item, index, arr) => array.findIndex(a => a[key] === item[key]) === index)
}

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.style.height = 0;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

const copyHtmlToClipboard = html => {
  const el = document.createElement('div')
  el.style.height = 0
  el.style.fontSize = '13px'
  el.style.color = 'black'
  el.setAttribute('tabindex', '0')
  document.body.appendChild(el)
  el.innerHTML = html
  el.focus()
  selectText(el)
  document.execCommand('copy')
  document.body.removeChild(el)
}

const removeSpecialCharacters = (str) => {
  return str.replace(/[!@#$%^&*(),.?":{}|<>+\-=\\[\]]/g, '');
}

const escapeSpecialCharacters = (str) => {
  return str.replace(/[!@#$%^&*(),.?":{}|<>+\-=\\[\]]/g, '\\$&');
}

// Check if service worker activated
const isSWActivated = () => {
  return navigator.serviceWorker 
    && navigator.serviceWorker.controller 
    && navigator.serviceWorker.controller.state == 'activated';
}

const horizontalScroll = (el) => {
  if(!el || el.onwheel)
    return
  el.onwheel = function(e) {
    if (e.deltaY > 0) el.scrollLeft += 100;
    else el.scrollLeft -= 100;
  };
}

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const registerGoogleAnaytic = () => {
  let gtagID;
  const hostname = window.location.hostname;
  switch (true) {
    case /sg\.chartdesk\.pro/i.test(hostname):
      gtagID = 'G-J1Y8Q1KDX8';
      break;
    case /chartdesk\.de/i.test(hostname):
      gtagID = 'G-L75163LH09';
      break;
    case /sapious\.com/i.test(hostname):
      gtagID = 'G-9TE8HJ690F';
      break;
    default:
      gtagID = 'G-V05681LVSF';
      break;
  }
  const d = document;
  const s = d.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = `https://www.googletagmanager.com/gtag/js?id=${gtagID}`
  d.getElementsByTagName("head")[0].appendChild(s);

  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', gtagID);
}

const dateWithoutTime = date => {
  return new Date(
    new Date(date || new Date()).toDateString()
  )
}

const updateServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations()
    await Promise.all(
      registrations.map(registration => registration.update())
    )
    setTimeout(() => {
      updateServiceWorker();
    }, 60 * 60 * 1000);
  }
}

const convertToKebabCase = (str) => {
  return str && str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');
}

const getDayIndexOfMonth = (d) => {
  let dates = []
  const day = d.getDay()
  const date = d.getDate()
  const month = d.getMonth()
  const year = d.getFullYear()
  d.setDate(1)
  d.setHours(0)
  while(d.getMonth() == month && d.getFullYear() == year) {
    if(d.getDay() == day) {
      dates.push(new Date(d.getTime()))
      d.setDate(d.getDate() + 7)
    } else 
      d.setDate(d.getDate() + 1)
  }
  return dates.findIndex(d => d.getDate() == date)
}

const getFileExtentsion = (filename) => {
  if(!filename) return ''
  const parts = filename.split('.');
  return parts[parts.length - 1];
}

const removeUnexpectedTags = (container, tags) => {
  tags.forEach(tag => {
    [...container.querySelectorAll(tag)].forEach(el => {
      el.remove()
    })
  })
}

const bytesToSize = (bytes) => {
  var sizes = ["B", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export { filter };

export default {
  filter,
  relativeDay,
  relativeDayToDate,
  relativeTime,
  chatTime,
  time_12,
  formatDay,
  storeWithExpiration,
  throttle,
  randomAvatar,
  userAvatar,
  reSetAuth,
  getToken,
  getMfaCode,
  getDataFromSw,
  sendMessageToSw,
  filterTitle,
  formatText,
  sanitizeHtml,
  filterReaders,
  removeSearchHtml,
  escapeHtmlTags,
  highlightSearchKey,
  isNullOrUndefined,
  deleteCharater,
  scrollToLoadMore,
  isEmptyObject,
  getUserId,
  is2FAEnabled,
  getTotpToken,
  functionBus,
  routeBasedEmails,
  routeBasedEmailKeys,
  updateAllCachedEmails,
  resetCachedEmails,
  equalListElements,
  removeLastElement,
  openWindow,
  selectText,
  getInitialName,
  changeTheme,
  convertHexToRGBA,
  compare2Object,
  storeWindowSize,
  distinctArray,
  copyToClipboard,
  copyHtmlToClipboard,
  removeSpecialCharacters,
  escapeSpecialCharacters,
  isSWActivated,
  appendTenantID,
  getTenantID,
  horizontalScroll,
  capitalizeFirstLetter,
  registerGoogleAnaytic,
  dateWithoutTime,
  plainTextToHtml,
  updateServiceWorker,
  convertToKebabCase,
  getDayIndexOfMonth,
  getFileExtentsion,
  removeUnexpectedTags,
  bytesToSize
};

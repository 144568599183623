import util from 'util'
import api from 'api'
import Cookies from 'js-cookie'
import {
  SET_USER,
  SET_TOKEN,
  SET_TEAM_MEMBERS,
  SET_DEACTIVATED_TEAM_MEMBERS,
  SET_COMPANY,
  SET_PROFILE,
  SET_MAILS_SETTINGS,
  SET_COMPANY_SETTINGS,
  SET_PERMISSIONS_SETTINGS,
  SET_MFA_CODE,
  SET_BASIC_DATA,
  SET_2FA_ENABLED,
  SET_ROLE_LIST,
  RESET_OPEN_TABS,
  SET_LOGGING_OUT
} from "../mutation-type";
import storageConstant from '@/common/constants/local-storage.constant';
import { handleMultiTenant } from '../../fetch/api';

import { router } from "../../main";
import { Message } from 'iview';

const savedUserSettings = util.storeWithExpiration.get(storageConstant.SETTING_MAILS) || {};
if (!savedUserSettings.compose_message_in_new_window) {
  savedUserSettings.compose_message_in_new_window = "true";
}

const state = {
  token: util.getToken(),
  mfa_code: util.getMfaCode(),
  twoFAEnabled: util.storeWithExpiration.get(storageConstant.TWO_FA_ENABLED),
  userInfo: util.storeWithExpiration.get(storageConstant.USER) || {user: {}, company: {}}, // 解决获取user_type报错导致无法跳转到登录页面的问题
  teamMembers: [],
  deactivatedTeamMembers: [],
  mailsSettings: savedUserSettings,
  companySettings: util.storeWithExpiration.get(storageConstant.SETTING_COMPANY) || {},
  permissionsSettings: util.storeWithExpiration.get(storageConstant.SETTING_PERMISSIONS),
  loggingOut: false
};

const getters = {
  userInfo: state => state.userInfo,
  teamMembers: state => {
    state.teamMembers = state.teamMembers.filter((member) => member.deleted_at == null)
    return state.teamMembers.sort((a,b) => {
      a.sortName = a.name || a.user_name || b.email
      b.sortName = b.name || b.user_name || b.email
      a.sortName = a.sortName.toLowerCase().trim();
      b.sortName = b.sortName.toLowerCase().trim();
      return a.sortName < b.sortName ? -1 : 1
    })
  },
  deactivatedTeamMembers: state => {
    state.deactivatedTeamMembers = state.deactivatedTeamMembers.filter((member) => member.deleted_at == null)
    return state.deactivatedTeamMembers.sort((a,b) => {
      a.sortName = a.name || a.user_name || a.email
      b.sortName = b.name || b.user_name || b.email
      a.sortName = a.sortName.toLowerCase().trim();
      b.sortName = b.sortName.toLowerCase().trim();
      return a.sortName < b.sortName ? -1 : 1
    })
  },
  setting_mails: state => state.mailsSettings,
  setting_company: state => state.companySettings,
  setting_permissions: state => state.permissionsSettings,
  twoFAEnabled: state => state.twoFAEnabled
};
const mutations = {
  [SET_USER]: (state, user) => {
    state.userInfo.user = user;
  },

  [SET_COMPANY]: (state, company) => {
    state.userInfo.company = company;
  },

  [SET_TOKEN]: (state, auth_token) => {
    state.token = auth_token;
  },

  [SET_MFA_CODE]: (state, mfa_code) => {
    state.mfa_code = mfa_code;
    const remember = util.storeWithExpiration.get(storageConstant.REMEMBER)
    if (remember) {
      Cookies.remove(storageConstant.MFA_CODE)
      util.storeWithExpiration.set(storageConstant.MFA_CODE, mfa_code, 0);
    } else {
      Cookies.set(storageConstant.MFA_CODE, mfa_code)
      localStorage.removeItem(storageConstant.MFA_CODE)
    }
  },

  [SET_TEAM_MEMBERS]: (state, users) => {
    state.teamMembers = [...users]
  },

  [SET_DEACTIVATED_TEAM_MEMBERS]: (state, users) => {
    state.deactivatedTeamMembers = [...users]
  },

  [SET_PROFILE]: (state, { company, user }) => {
    if(!util.isEmptyObject(user)) {
      state.userInfo.user.avatar = user.avatar
      state.userInfo.user.name = user.name
      state.userInfo.user.timezone_id = user.timezone_id
    } else {
      state.userInfo.user = {};
    }
    state.userInfo.company = company;
    util.storeWithExpiration.set(storageConstant.USER, state.userInfo, 0);
  },

  [SET_MAILS_SETTINGS]: (state, obj) => {
    state.mailsSettings = util.isEmptyObject(obj) ? {} : Object.assign(state.mailsSettings, obj);
    util.storeWithExpiration.set(storageConstant.SETTING_MAILS, state.mailsSettings, 0)
  },

  [SET_COMPANY_SETTINGS]: (state, obj) => {
    state.companySettings = obj
    util.storeWithExpiration.set(storageConstant.SETTING_COMPANY, state.companySettings, 0)
  },

  [SET_PERMISSIONS_SETTINGS]: (state, obj) => {
    state.permissionsSettings = obj
    util.storeWithExpiration.set(storageConstant.SETTING_PERMISSIONS, state.permissionsSettings, 0)
  },
  [SET_2FA_ENABLED]: (state, enabled) => {
    state.twoFAEnabled = enabled;
    util.storeWithExpiration.set(storageConstant.TWO_FA_ENABLED, enabled, 0)
  },
  [SET_LOGGING_OUT]: (state, val) => {
    state.loggingOut = val;
  }
};

const actions = {
  Login({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      // const fetchApi = params.captcha ? api.login(params) : api.loginWithoutCapcha(params);
      const fetchApi = api.login_session_cookie(params)
      fetchApi
        .then(async (res) => {
          if(!res || !res.auth_token) {
            reject(res);
            return;
          }
          await dispatch("handleLoginSuccess", res);
          resolve(res);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  },

  loginWith2FA({ dispatch }, code) {
    return dispatch('verify2FACode', code).then(data => {
      dispatch("loadDataAfterLogin");
    });
  },

  verify2FACode({commit, state}, authenCode) {
    return api.verify_2fa_code({code: authenCode}, state.token).then(res => {
      if(res && res.mfa_code) {
        commit(SET_MFA_CODE, res.mfa_code);
        commit(SET_2FA_ENABLED, true);
      }
    })
  },

  disable2FA({commit, dispatch}, authenCode) {
    return dispatch('save2FASetting', { enable: false, authenCode}).then(_ => {
      commit(SET_MFA_CODE, '');
      commit(SET_2FA_ENABLED, false);
    })
  },

  async save2FASetting({commit}, { enable, authenCode }) {
    const param = {
      keys: ['google_authenticator'],
      values: [`${enable}`]
    }
    authenCode && (param.code = authenCode);
    await api.set_user_settings(param);
    commit(SET_MAILS_SETTINGS, {...state.mailsSettings, google_authenticator: `${enable}`});
  },

  loginWithSSO({ dispatch }, { token, sso_tenant_id }) {
    return new Promise((resolve, reject) => {
      api.login_with_sso(token, sso_tenant_id)
        .then(async (res) => {
          if(!res || !res.auth_token) {
            reject(res);
            return;
          }
          await dispatch("handleLoginSuccess", res);
          resolve(res);
        })
        .catch(function (error) {
          reject(error);
        });
    })
  },

  loadQRCode({ state }) {
    return api.load_qr_image(state.userInfo.user.id, state.token);
  },

  async handleLoginSuccess({ commit, dispatch }, res) {
    commit(SET_2FA_ENABLED, res.enable_2fa);
    commit(SET_TOKEN, res.auth_token);
    commit(SET_USER, res.user);
    commit(SET_COMPANY, res.company);
    commit(SET_MAILS_SETTINGS, res.user_settings);
    commit(SET_PERMISSIONS_SETTINGS, res.permissions);
    commit(SET_PROFILE, { company: res.company, user: res.user });
    commit(SET_COMPANY_SETTINGS, res.company_settings);
    
    window.timeGap = +new Date(res.auth_at) - Date.now();
    localStorage.setItem("timeGap", JSON.stringify(window.timeGap));

    util.changeTheme(res.user_settings.theme_colour);
    handleMultiTenant();
    const no2FA = !res.enable_2fa && (!res.user_settings.google_authenticator || res.user_settings.google_authenticator == 'false');
    no2FA && await dispatch("loadDataAfterLogin");
  },

  async loadDataAfterLogin({dispatch}) {
    storeAuthToken(state.token);
    await dispatch("getSystemBookmark", true);
    dispatch("loadBasicData");
    dispatch("getRoles");
    dispatch("GetTeamMembers", { active: true });
    dispatch("getCompanySettings");
    state.companySettings.calendar_module == '1' && dispatch("getCalendarResources");
    state.companySettings.limit_hashtag == '1' && dispatch("getTags");
    dispatch("getOpenTabs");
  },
  
  async getSystemBookmark({ commit, dispatch }, needUpdateSystemBookmark) {
    const res = await api.get_bookmark();
    const serverBookmark = res && res.auto_bookmarks && res.auto_bookmarks.length > 0 && res.auto_bookmarks[0] || undefined;
    const userBookmark = res && res.bookmarks && res.bookmarks.length > 0 && res.bookmarks[0] || undefined;
    dispatch("saveLocalBookmark", serverBookmark)
    needUpdateSystemBookmark && commit("setSystemBookmark", serverBookmark)
    commit("setUserBookmark", userBookmark)
  },
  saveLocalBookmark(_, bookmark) {
    if (!bookmark) return;
    window.CDConfig.set("local_bookmark", bookmark);
  },
  Logout({ dispatch }) {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      util.sendMessageToSw({ message: "canLogout" }, (canLogout) => {
        if (canLogout) {
          Message.destroy();
          return dispatch("handleLogout");
        } else {
          util.functionBus.showLogoutWarning();
        }
      });
    } else {
      return dispatch("handleLogout");
    }
  },

  handleLogout({ commit, dispatch }) {
    commit("REMOVE_ALL_INAPP_WINDOW");
    return api.logout_session_cookie() //TODO For testing purpose, revert later
      .then((_) => {
        dispatch("resetUserData");
        router.push("/login");
        location.reload(true);
      })
      .catch((error) => {
        console.log(error)
      });
  },

  resetUserData({commit}) {
    commit(SET_TOKEN, '');
    commit(SET_MFA_CODE, '');
    commit(SET_PROFILE, { user: {}, company: {} });
    commit(SET_COMPANY, {});
    commit(SET_MAILS_SETTINGS, {});
    commit(SET_COMPANY_SETTINGS, {});
    commit(SET_PERMISSIONS_SETTINGS, {});
    commit(SET_BASIC_DATA, {});
    commit(SET_TEAM_MEMBERS, []);
    commit(SET_DEACTIVATED_TEAM_MEMBERS, []);
    commit(SET_ROLE_LIST, []);
    commit(SET_USER, {});
    commit(RESET_OPEN_TABS);
    util.storeWithExpiration.set(storageConstant.LINES, []);
    window.CDConfig.clear();
    localStorage.removeItem(storageConstant.COPIED_ATTACHMENTS)
  },

  getCompanySettings({ commit }) {
    api
      .get_company_settings()
      .then(({ settings }) => commit(SET_COMPANY_SETTINGS, settings))
      .catch(error => console.log(error));
  },
  updateCompanySetting({commit}, params) {
    return api
      .set_company_settings(params)
      .then((res) => {
        return new Promise((resolve, reject) => {
          if(!res || !res.settings) {
            reject(res)
            return
          }
          commit("SET_COMPANY_SETTINGS", res.settings, 0)
          resolve(res)
        })
      })
      .catch(error => {
        return new Promise((_, reject) => {
          reject(error)
        })
      })
  },

  GetTeamMembers({commit}, params) {
    return api
      .get_team_members({ all_users: true })
      .then(res => {
        return new Promise((resolve, reject) => {
          if (res.users) {
            const activatedUsers = [];
            const deactivatedUsers = [];
            res.users.forEach(user => {
              if (user.active) {
                activatedUsers.push(user);
              } else {
                deactivatedUsers.push(user);
              }
            });

            commit(SET_TEAM_MEMBERS, activatedUsers);
            commit(SET_DEACTIVATED_TEAM_MEMBERS, deactivatedUsers);
            resolve(res);
          } else {
            reject(res);
          }
        });
      })
      .catch(function(error) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      });
  },

  async setTheme({commit}, themeName) {
    const param = {
      keys: ['theme_colour'],
      values: [themeName]
    }
    await api.set_user_settings(param);
    commit(SET_MAILS_SETTINGS, {...state.mailsSettings, theme_colour: themeName});
    util.changeTheme(themeName);
  },

  async updatePreviewMailSetting({commit}, isPreview) {
    const param = {
      keys: ['preview_mail_body'],
      values: [`${isPreview}`]
    }
    await api.set_user_settings(param);
    commit(SET_MAILS_SETTINGS, {...state.mailsSettings, preview_mail_body: `${isPreview}`});
  },
  
  async setOpenAICommand({commit}, command) {
    const param = {
      keys: ['openai_command'],
      values: [command]
    }
    await api.set_user_settings(param);
    commit(SET_MAILS_SETTINGS, {...state.mailsSettings, openai_command: command});
  },

  async updateHeaderFooterContent({commit}, param) {
    await api.set_user_settings(param);
    commit(SET_MAILS_SETTINGS, {...state.mailsSettings, [param.keys[0]]: param.values[0]});
  }
}

function storeAuthToken(auth_token) {
  const remember = util.storeWithExpiration.get(storageConstant.REMEMBER)
  if (remember) {
    Cookies.remove(storageConstant.TOKEN)
    util.storeWithExpiration.set(storageConstant.TOKEN, auth_token, 432000000);
  } else {
    Cookies.set(storageConstant.TOKEN, auth_token)
    localStorage.removeItem(storageConstant.TOKEN)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};

const LocalStorageConstant = {
  UPDATE_STAR_MAIL: "updateStarMail",
  FAILURE_LOGIN_COUNT: "failureLoginCount",
  MFA_CODE: "mc",
  TWO_FA_ENABLED: "en",
  TOKEN: "token",
  USER: "user",
  SETTING_MAILS: "setting_mails",
  SETTING_COMPANY: "setting_company",
  SETTING_PERMISSIONS: "setting_permissions",
  REMEMBER: "remember",
  QUERY_HANDLING: "queryHandling",
  LOGEDIN_EMAIL: "logedInEmail",
  LINES: "lines",
  BASIC: "basic",
  EXPAND_PREVIEW_VERTICAL: "expandPreviewVertical",
  EXPAND_PREVIEW_HORIZONTAL: "expandPreviewHorizontal",
  GMAIL_ACCOUNT: 'gmailAccount',
  MICROSOFT_ACCOUNT: 'microsoftAccount',
  WINDOW_SIZE: 'windowSize',
  EMAIL_TO_BE_READ: 'emailTobeRead',
  HIDE_FILED: 'hide_filed',
  HIDE_HISTORICAL_EMAILS: 'hide_historical_emails',
  HIDE_ASSIGNED: 'hide_assigned',
  OPEN_MAIL_TABS: 'open_mail_tabs',
  FOCUSED_MODE_ENABLED: 'focusedModeEnabled',
  OPEN_MODE: 'open_mode',
  COPIED_ATTACHMENTS: 'copy_attachments'
};
export default LocalStorageConstant;
import api from 'api';
import util from 'util'
import {
  ADD_LINES,
  ADD_LINES_ALL,
  ADD_THREAD_EMAILS,
  ADD_BODY_TO_HISTORY_EMAIL,
  SET_DRAGGED_CONTACT,
  ADD_EMAIL_TO_READ,
  RESET_EMAIL_TO_READ,
  UPDATE_LINE_STATUS,
  REMOVE_RIGHT_CLICK_FOLDER,
  START_LOADING_THREAD,
  FINISH_LOADING_THREAD,
  SET_CONFIGURED_TAGS,
  ADD_EXPANDING_NAV_ITEM,
  REMOVE_EXPANDING_NAV_ITEM
} from './mutation-type';
import storageConstant from '@/common/constants/local-storage.constant';
import {Modal} from 'iview'
import appConstant from '@/common/constants/app.constant';

const updateLineData = (commit, res) => {
  commit(ADD_LINES, res.lines);
  commit(ADD_LINES_ALL, res);
  util.storeWithExpiration.set(storageConstant.LINES, res.lines, 0);
}

const updateUI = (res, commit, params) => {
  res.email.body = res.email.body.split("<a").join("<a target='_blank'");
  res.email.body_type == "plain_text" ?
    '<span style="white-space:pre-wrap">' + res.email.body + '</span>' :
    res.email.body;
  let body = res.email.body;
  let index = params.index;
  let payload = {
    body: body,
    index: index,
    readers: res.readers,
    archived: res.email.archived,
    body_type: res.email.body_type,
    attachments: res.email.attachments  
  }
  commit(ADD_BODY_TO_HISTORY_EMAIL, payload);
}

export default {
  composeMail({commit, state}, urlObj) {
    if (urlObj && urlObj.inNewWindow) {
      let url = "";

      if (urlObj.query) {
        url = `${urlObj.path}`
        const queries = urlObj.query ? Object.keys(urlObj.query) : {}
        if (queries.length > 0) {
          url += "?";
          const queryString = queries.filter(key => !!urlObj.query[key])
                                     .map(key => `${key}=${encodeURIComponent(urlObj.query[key])}`)
                                     .join("&");
          url += queryString;
        }
      } else {
        url = "sendMail"
      }

      util.openWindow(url || "sendMail");
      return;
    }

    const params = urlObj.path ? urlObj : {path: "sendMail", query: {}}
    params.key = `compose-${Date.now()}`;
    params.visible = true;
    params.type = appConstant.inappType.COMPOSE;

    commit("ADD_INAPP_COMPOSE_WINDOW", params);
  },
  //mails
  loadLines({
    commit
  }) {
    util.getDataFromSw('nav-lines', data => {
      updateLineData(commit, data)
    })
    return api.get_lines().then((res) => {
      if (res.lines) {
        updateLineData(commit, res);
        return res.lines;
      }
    }).catch((error) => {
      console.log(error);
    })
  },
  updateLines({
    commit
  }, data) {
    updateLineData(commit, data)
  },

  updateLineStatus({commit}, updatedLine){
    commit(UPDATE_LINE_STATUS, updatedLine);
  },

  setNestedFolder({
    commit
  }, folder) {
    commit('SET_NESTED_FOLDER', folder);
  },
  handleRightClickFolder({
    commit, rootState
  }, folder) {
    switch (folder.action) {
      case "copyFolder":
        return commit("SET_FOLDER_TO_COPY", folder);
      case "pasteFolder":
        return api.copy_folder_structure({
          folder_id: rootState.folderToCopy.copyiedId,
          parent_id: folder.folder.id,
          name: folder.folder.newCoppiedName || "New folder",
        });
      default:
        return commit("SET_DATA_RIGHT_CLICK", folder);
    }
  },
  removeFolderRightClickData({ commit }) {
    commit(REMOVE_RIGHT_CLICK_FOLDER);
  },
  setDragedMail({
    commit
  }, mails) {
    commit('SET_DRAGED_MAIL', mails);
  },
  sendComments({
    dispatch,
    commit
  }, params) {
    return api.send_comment(params).then((res) => {
      return new Promise((resolve, reject) => {
        resolve(res);
      });
    }).catch((error) => {
      console.log(error);
    });
  },
  async loadThreadEmails({
    commit
  }, { target_id, history, page }) {
    try {
      let res = {};
      commit(START_LOADING_THREAD)
      if (history) {
        res = await api.get_history_emails({ target_id });
        res.histories = res.histories.sort((x, y) => {
          return new Date(y.received_at) - new Date(x.received_at);
        });
      } else {
        res = await api.get_contact_emails({ target_id, per_page: 15, page });
        res.histories = res.histories.reverse();
      }

      commit(ADD_THREAD_EMAILS, {
        originalEmailId: history ? target_id : undefined,
        maxThreadEmail: res.count || res.histories.length,
        emails: res.histories,
        reset: page === 1 || history
      });
      return res;
    } catch (error) {
      console.log(error)
      commit(FINISH_LOADING_THREAD);
    }
  },
  loadThreadEmailsBody({ commit }, params) {
    return api.get_email_details(params).then((res) => {
      updateUI(res, commit, params);
    }).catch((error) => {
      console.log(error);
    })
  },
  readEmail({
    commit,
  }, params) {
    commit(ADD_EMAIL_TO_READ, params.rowId);
  },
  async readMultipleEmails({rootState, commit}, { retry }) {
    if (rootState.emailTobeRead.length > 0) {
      const email_ids = [...new Set([...rootState.emailTobeRead])];
      commit(RESET_EMAIL_TO_READ);
      api.mark_as_reads({ email_ids }).then(res => {
        if (!retry || !res || !res.email_ids) return;

        if (email_ids.length === res.email_ids.length) return;
        const unsentEmailIds = [];
        email_ids.forEach(id => {
          if (res.email_ids.includes(id)) return;
          unsentEmailIds.push(id);
        })
        commit(ADD_EMAIL_TO_READ, ...unsentEmailIds);
      }).catch(error => {
        console.log(error);
        if (!/network/i.test(error)) return;
        retry && commit(ADD_EMAIL_TO_READ, ...email_ids);
      })
    }
  },
  //ship/contact
  setDraggedContacts({
    commit
  }, contacts) {
    commit(SET_DRAGGED_CONTACT, contacts);
  },
  getTags({ commit }) {
    return api
      .get_tags()
      .then(tags => {
        commit(SET_CONFIGURED_TAGS, tags || []);
      });
  },
  toggleNavItem({ commit }, { expanding, name }) {
    expanding && commit(ADD_EXPANDING_NAV_ITEM, name)
    !expanding && commit(REMOVE_EXPANDING_NAV_ITEM, name)
  }
};

import { fromEvent } from "rxjs";
import { distinctUntilKeyChanged, filter } from 'rxjs/operators';
import Mark from "mark.js";
import appConstant from "../common/constants/app.constant";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import utils from "@/utils";
import api from "@/fetch/api";

export const globalMixin = {
  methods: {
    checkPermission: function(name) {
      const userInfo = this.$store.getters.userInfo;
      const permissions = this.$store.getters.setting_permissions;
      if (userInfo.user && userInfo.user.user_type === "admin") {
        return true;
      } else if (
        permissions &&
        permissions[name] &&
        userInfo.user &&
        userInfo.user.user_type === "member"
      ) {
        return true;
      } else {
        return false;
      }
    },
    handleLineColor(lineIds) {
      const defaultColor = "transparent";
      if (!lineIds || lineIds.length > 1 || !this.lines_all)
        return defaultColor;

      let lines = this.lines_all.full_lines;
      (!lines || lines.length == 0) && (lines = this.lines_all.lines);
      const line = lines.find(line => line.id === lineIds[0]);
      if (!line) return defaultColor;
      return line.colour;
    },
    openExternalUrl(url) {
      let convertedURL = url;
      if (!/http/.test(url)) {
        convertedURL = `http://${url}`;
      }
      window.open(convertedURL, "_blank");
    },
    $route_folder_id(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.folder_id;
      if (tab && tab.name_type == appConstant.tabTypes.FOLDER)
        return tab && tab.data && tab.data.id;
    },
    $route_folder_name(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.folder_name;
      if (tab && tab.name_type == appConstant.tabTypes.FOLDER)
        return tab && tab.data && tab.data.name;
    },
    $route_folder_type(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.type;
      if (tab && tab.name_type == appConstant.tabTypes.FOLDER)
        return tab && tab.data && tab.data.folder_type;
    },
    $route_line_id(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.line_id;
      if (tab && tab.name_type == appConstant.tabTypes.LINE)
        return tab.data && tab.data.id;
    },
    $route_line_name(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab) {
        const line = this.lines && this.lines.find(l => l.id == this.$route_line_id());
        return line && `${this.$route_box_name()} (${line.username})`;
      }
      if (tab && tab.name_type == appConstant.tabTypes.LINE)
        return tab && tab.data && `${tab.data.parentName} (${tab.data.name})`;
    },
    $route_box_name(routeQuery) {
      const query = routeQuery || this.$route.query;
      if(query.me)
        return "@ Mentions"
      if(query.is_star == 'true')
        return "Starred Mails"
      if (query.archived)
        return "Archive";
      if (query.box === "Draft")
        return "Draft";
      if (query.box === "Sent" && query.status === 'pending')
        return "Outbox";
      if (query.status === "sent")
        return "Sent";
      if (query.box)
        return utils.capitalizeFirstLetter(query.box);
      return "Mail Activity";
    },
    $route_box(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.box ? query.box.toLowerCase() : undefined;
      if (tab && tab.name_type == appConstant.tabTypes.LINE && tab.data) {
        return tab.data.parentId == 'outbox' ? 'sent'
          : !['archived', 'sent', 'mails'].includes(tab.data.parentId) ? tab.data.parentId
            : undefined;
      }
    },
    $route_archived(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.archived;
      if (tab && tab.name_type == appConstant.tabTypes.LINE) {
        return tab && tab.data && tab.data.parentId && tab.data.parentId.toLowerCase() == 'archived' || undefined;
      }
    },
    $route_status(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.status;
      if (tab && tab.data && tab.data.parentId && tab.name_type == appConstant.tabTypes.LINE) {
        return tab.data.parentId.toLowerCase() == 'outbox' ? 'pending'
          : tab.data.parentId.toLowerCase() == 'sent' ? 'sent'
            : undefined;
      }
    },
    waitForExpect(refName, callback) {
      const interval = setInterval(() => {
        if (this.$refs[refName]) {
          callback && callback()
          clearInterval(interval)
        }
      }, 50)
    }
  },
  computed: {
    ...mapGetters(['openingTabs', 'lines_all', 'setting_mails']),
    composeInNewWindow() {
      return this.setting_mails.compose_message_in_new_window === "true";
    },
  }
};

export const zohoRegister = {
  mounted() {
    if (window.ZohoHCAsap) return;
    const devMode = /staging|localhost/.test(location.href);

    const d = document;
    const s = d.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = devMode
      ? "https://desk.zoho.com/portal/api/web/inapp/381504000000551073?orgId=686867340"
      : "https://desk.zoho.com/portal/api/web/inapp/381504000000551153?orgId=686867340";
    d.getElementsByTagName("head")[0].appendChild(s);

    window.ZohoHCAsap = function (a, b) {
      ZohoHCAsap[a] = b;
    };
    window.ZohoHCAsapReady = function (o) {
      if (
        ((window.ZohoHCAsap__asyncalls = window.ZohoHCAsap__asyncalls || []),
        window.ZohoHCAsapReadyStatus)
      ) {
        o && window.ZohoHCAsap__asyncalls.push(o);
        for (var a = window.ZohoHCAsap__asyncalls, s = 0; s < a.length; s++) {
          var n = a[s];
          n && n();
        }
        window.ZohoHCAsap__asyncalls = null;
      } else o && window.ZohoHCAsap__asyncalls.push(o);
    };
    ZohoHCAsapReady(function () {
      ZohoHCAsap.Action("hideLauncher");
    });
  }
}

export const mapboxCSS = {
  beforeCreate() {
    const d = document;
    const l = d.createElement("link");
    l.type = "text/css";
    l.rel = "stylesheet";
    l.href = "/static/style/mapbox-gl.css";
    d.getElementsByTagName("head")[0].appendChild(l);
  },
};

export const convertBody = {
  methods: {
    convertBody(email) {
      if (email.previewPlainText) {
        return utils.escapeHtmlTags(email.plain_text) || "";
      }

      if (!email.body) return "";
      if (email.body_type === "plain_text") {
        email.body = utils.escapeHtmlTags(email.body) || "";
      }
      email.body = email.body.replace(/<a/g, "<a target='_blank'");
      return email.body;
    }
  }
};

export const hotkeyHandler = {
  data() {
    return {
      hotkeySubcription: undefined
    }
  },
  mounted() {
    this.registerHotkeyHandler();
  },
  destroyed() {
    this.removeHotkeyHandler();
  },
  methods: {
    registerHotkeyHandler() {
      this.hotkeySubcription = fromEvent(document, 'keydown')
      .pipe(
        filter(event => !event.repeat) // Using filter to remove repeat event when long press.
      )
      .subscribe(e => {
        const isActionKey = e.key == 'Escape' || e.ctrlKey || e.metaKey || e.shiftKey || e.altKey;
        const { nodeName, isContentEditable } = document.activeElement;
        const type = document.activeElement.getAttributeNode("type");
        
        if (isContentEditable) 
          return
          
        switch (nodeName) {
          case 'INPUT':
            if(type && type.value != 'checkbox')
              return
            break;
          case 'TEXTAREA':
          case 'SELECT':
            if(!isActionKey)
              return;
        }
        this.handleHotkey(e)
      })
    },
    removeHotkeyHandler() {
      this.hotkeySubcription.unsubscribe(); // Unsubscribe to remove all event listeners.
    },
    handleHotkey(_e) {  } // abstract method, will be overrided by component.
  }
}

export const assignmentSocket = {
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    ...mapMutations(["UPDATE_MAIL_LIST_ITEMS"]),
    handleAssignmentSocket(data) {
      switch(data.action) {
        case 'create':
          const assignmentItem = {
            id: data.id,
            assignable_id: data.assignable_id,
            email_id: data.email_id,
            assignable_type: data.assignable_type
          }

          this.UPDATE_MAIL_LIST_ITEMS({
            ids: [data.email_id],
            changedProps: (email) => {
              if (!email.assignment_ids) return {};

              const existing = email.assignment_ids.some(a => a.assignable_id === data.assignable_id);
              if (existing) return {};

              return { assignment_ids: [...email.assignment_ids, assignmentItem] || [] }
            }
          })
          break;
        case 'delete':
          this.UPDATE_MAIL_LIST_ITEMS({
            ids: [data.email_id],
            changedProps: (email) => ({ assignment_ids: email.assignment_ids.filter(a => a.assignable_id !== data.assignable_id) || [] })
          })
          break;
        case 'closed':
          this.UPDATE_MAIL_LIST_ITEMS({
            ids: [data.email_id],
            changedProps: (_) => ({ closed: data.closed })
          })
          break;
      }
    }
  }
}

export const mailDetailMixin = {
  data() {
    return {
      subscription: undefined,
      parserResult: { name: '', html: '' },
      showParserResult: false,
      showOpenAITextbox: false,
      openAICommandText: '',
      openAIRetryCount: 0,
      openAIRetryInterval: undefined,
      executing: false
    }
  },
  mixins: [assignmentSocket],
  mounted() {
    this.subscribeWSChannel();
  },
  beforeDestroy() {
    this.subscription.unsubscribe();
  },
  methods: {
    ...mapActions(['setOpenAICommand']),
    subscribeWSChannel() {
      this.subscription = this.$ActionCable.subscriptions.create({channel: 'NotificationsChannel'}, {
        connected: () => {
          console.log('Mail detail WS connected');
        },
        received: (data) => {
          switch (data.type) {
            case 'Assignment':
              if(!data.message || data.message.email_id != this.mailDetail.id) 
                return;
              this.handleAssignmentSocket(data.message, this.mailDetail)
              break;
            case "UpdateCloseStatus":
              if(!data.message || data.message.length == 0) 
                return;
              const item = data.message.find(m => m.id == this.mailDetail.id);
              item && this.handleAssignmentSocket({...item, action: 'closed' }, this.mailDetail)
              break;
            case "OpenaiResponse":
              if(!data.message || data.message.length == 0) 
                return;
              this.handleOpenAISocket(data.message)
              break;
          }
        },
        disconnected: () => {
          console.log('Mail detail WS disconnected');
        }
      });
    },
    handleOpenAISocket(data) {
      if(!this.executing) 
        return

      this.resetOpenAIData()

      if(!data || !data.body) {
        this.parserResult = { name: 'No data', html: '' };
        return;
      }
      const name = '';
      const html = data.body.replace(/\n/gi, '<br>');
      this.parserResult = { name, html };
    },
    toggleOpenAITextbox() {
      this.showOpenAITextbox = !this.showOpenAITextbox
    },
    async executeOpenAICommand (mailId) {
      if(!this.openAICommandText)
        return this.$Message.error('Please enter a command')

      this.showOpenAITextbox = false
      if(this.setting_mails.openai_command !== this.openAICommandText) {
        await this.setOpenAICommand(this.openAICommandText)
      }

      try {
        this.showParserResult = this.executing = true;
        this.parserResult = { name: 'Loading...', html: 'Your request is processing, please wait' };
        await api.executeOpenAICommand(mailId);

        this.openAIRetryInterval = setInterval(async () => {
          if(this.executing && this.openAIRetryCount >= 2) {
            this.resetOpenAIData()
            this.parserResult = { name: 'No data', html: '' }
            return
          }
          if(this.executing) {
            this.openAIRetryCount++
            this.parserResult = { name: `Attempting (${this.openAIRetryCount})...`, html: 'Request might take long time, we are attempting reload' };
            await api.executeOpenAICommand(mailId);
          }
        }, 60000)
      } catch (err) {
        this.executing = false
        let message = err.response.data.message
        message = message && message.includes('Request too long') ? 'Message is too long, please try another' : 'Some errors occurred, please try again later'
        this.parserResult = { name: message, html: '' };
      }
    },
    resetOpenAIData() {
      this.executing = false
      this.openAIRetryCount = 0
      clearInterval(this.openAIRetryInterval)
    },
    copyParserResult() {
      utils.copyHtmlToClipboard(this.parserResult.html);
      this.$Message.success('Content copied')
    },
    onOpenAIDone() {
      this.resetOpenAIData()
    }
  },
  watch: {
    'setting_mails.openai_command': {
      immediate: true,
      handler(val) {
        this.openAICommandText = val
      }
    }
  }
}
let markInstance;
export const highlightSearch = {
  methods: {
    highlightSearch(context, searchKeys) {
      markInstance = new Mark(context);
      searchKeys.forEach((keyword) => {
        keyword = utils.escapeSpecialCharacters(keyword);
        const regex = new RegExp(`(?<!\\w)${keyword}`, "gim");
        markInstance.markRegExp(regex, {
          separateWordSearch: false,
          element: 'span',
          className: 'text-highlight'
        });
      });
    },
  },
};

export const searchActions = {
  methods: {
    handleSearchAgain() {
      this.reloadRouter();
    },
    handleExitSearch() {
      this.reloadRouter("exit-search");
    },
    reloadRouter(action) {
      const { query, path } = this.$route;
      if (action === "exit-search") {
        delete query.search_key;
        delete query.search_id;
      }
      delete query.rand;
      let rebuiltQuery = `${path}?`;
      const queryArr = [];
      for (const key in query) {
        queryArr.push(`${key}=${encodeURIComponent(query[key])}`);
      }
      rebuiltQuery += queryArr.join("&");
      const rand = +new Date();
      this.$router.replace(rebuiltQuery + `&rand=${rand}`);
    },
  },
};

export const popupPosition = {
  methods: {
    computePosition(rel, popupEl, position, padding={top: 8, right: 4, bottom: 8, left: 4}) {
      if(!rel || !popupEl)
        return;
      let top, left, right;
      const relElRect = rel.getBoundingClientRect ? rel.getBoundingClientRect() : rel;
      const popupElRect = popupEl.getBoundingClientRect();

      const exceedTop = relElRect.top < popupElRect.height + padding.top;
      const exceedRight = relElRect.right + popupElRect.width + padding.right > innerWidth;
      const exceedBottom = relElRect.top + popupElRect.height > innerHeight;
      const exceedLeft = relElRect.left < popupElRect.width + padding.left;

      switch(position) {
        case('top'):
          top = exceedTop && !exceedBottom ? relElRect.top + relElRect.height + padding.top : relElRect.top - popupElRect.height - padding.top;
          left = relElRect.left;
          right = window.innerWidth - relElRect.right;
          break
        case('right'):
          top = exceedBottom && !exceedTop ? relElRect.bottom - popupElRect.height : relElRect.top;
          left = exceedRight && !exceedLeft ? relElRect.left - popupElRect.width - padding.right : relElRect.left + relElRect.width + padding.right;
          break
        case('bottom'):
          top = exceedBottom && !exceedTop ? relElRect.top - popupElRect.height - padding.bottom : relElRect.top + relElRect.height + padding.bottom;
          left = exceedRight && !exceedLeft ? relElRect.right - popupElRect.width : relElRect.left;
          right = window.innerWidth - relElRect.right;
          break
        case('left'):
          top = exceedBottom && !exceedTop ? relElRect.bottom - popupElRect.height : relElRect.top;
          left = exceedLeft && !exceedRight ? relElRect.left + relElRect.width + padding.left : relElRect.left - popupElRect.width - padding.left;
          break
      }
      return { top, left, right };
    }
  }
}

export const tabUtil = {
  methods: {
    ...mapMutations(['SET_ACTIVE_TAB']),
    setActiveAndGotoTab(tab) {
      this.SET_ACTIVE_TAB(tab);
      const routeData = {
        path: this.$route.path,
        query: { ...this.$route.query, 
          tab: tab.name_type !== appConstant.tabTypes.PAGE ? tab.name : undefined,
          subtab: undefined
        }
      }
      this.$router.replace(routeData).catch(() => {});
    },
  }
}

export const addUserMixin = {
  methods: {
    validateUsername(value) {
      if(value == '') {
        return 'Please enter username'
      } else if (value.length < 3 || value.length > 20) {
        return 'Must be at least 3 characters but not more than 20 characters'
      } else if(!/^[a-zA-Z0-9.-]+$/.test(value)) {
        return 'Only English alphabetic characters, numbers, hyphens (-), and dots (.) allowed'
      }
    },
    validatePassword(value) {
      if (value === '')
        return 'Please enter password';

      let num = this.checkStrong(value);
      if (num === 0)
        return 'Please enter at least 8 characters';
      if (num > 0 && num < 3)
        return 'Must contain at least 1 letter, 1 number and 1 special character';
    },
    validateConfirmPassword(value, pass) {
      if (value === '')
        return 'Please enter password confirmation';
      if (value !== pass)
        return 'The passwords do not match';
    },
    checkStrong(val){
      var modes = 0;
      if (val.length < 8) return 0;
      if (/\d/.test(val)) modes++;
      if (/[a-z]/.test(val)) modes++;
      if (/\W/.test(val)) modes++;
      return modes;
    },
  }
}

export const beforeLeaveConfirmation = {
  data() {
    return {
      nextRoutePath: ''
    }
  },
  created() {
    window.addEventListener("beforeunload", this.onWindowClose);
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.onWindowClose);
  },
  beforeRouteLeave (to, from , next) {
    if(this.user.loggingOut)
      next();

    this.askBeforeLeave(
      () => {
        this.nextRoutePath = to.fullPath;
        next(false);
      },
      () => {
        next();
      }
    )
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    ...mapMutations(['SET_LOGGING_OUT']),
    ...mapActions(['Logout']),
    askBeforeLeave(hasChangedCallback, noChangedCallback) {
      if(this.hasChanged()) {
        this.confirmBeforeLeave = true;
        hasChangedCallback && hasChangedCallback();
      } else {
        noChangedCallback && noChangedCallback();
      }
    },
    onWindowClose(e) {
      if(this.hasChanged()) {
        e.preventDefault();
        e.returnValue = false;
      }
      return undefined;
    },
    logout() {
      this.Logout().then(() => {
        this.$router.push('/login');
        this.SET_LOGGING_OUT(false);
      });
    },
  },
  watch: {
    "user.loggingOut"(val) {
      val && this.askBeforeLeave(
        undefined,
        () => this.logout()
      );
    }
  }
}

export const searchFolderMixin = {
  data() {
    return {
      folderToSearch: undefined,
      searchFolderActions: {
        HIGHLIGHT: 0,
        OPEN_FOLDER: 1
      }
    }
  },
  methods: {
    searchDone() {
      let folderEl = this.$refs[`folderItem${this.folderToSearch.id}`];
      if(!folderEl || !folderEl[0])
        return this.folderToSearch = null;
        
      folderEl = folderEl[0]
      switch(this.folderToSearch.action) {
        case this.searchFolderActions.HIGHLIGHT:
          folderEl.highlightFolder();
          folderEl.$el && folderEl.$el.scrollIntoView && folderEl.$el.scrollIntoView({ behavior: 'smooth', block:'center' });
          break;
        case this.searchFolderActions.OPEN_FOLDER:
          folderEl.selectFolder();
          break;
      }
      this.folderToSearch = null;
    },
  }
}

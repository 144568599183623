<template>
  <div id="app">
    <div v-if="announcement" class="notice maintenance-notice">
      <div v-html="announcement" />
      <Icon title="close" type="md-close" @click="handleCloseMaintenanceNotice" />
    </div>
    <div v-if="!online" class="notice">
      <Icon type="md-warning" />Connection lost.&nbsp;&nbsp;The displayed data may be out of date. &nbsp;<a class="notice-action" onclick="window.location.reload(true)">Refresh</a>
    </div>
    <router-view @route-data-loaded="changeTitle" v-if="isRouterAlive"></router-view>
    <Modal v-model="logoutWarning" width="400" class="dialog-no-header dialog-center-footer">
      <span>Do you want to save draft before leaving?</span>
      <div slot="footer" class="save-draft-footer">
        <Button size="small" tabindex="1" ref="cancelConfirm"
                @click="logoutWarning=false">Cancel</Button>
        <Button size="small" tabindex="2"
                @click="discardSaveDraftBeforeLeaving">Discard</Button>
        <Button size="small" tabindex="3"
                @click="saveDraftBeforeLeaving">Save</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
  import util from 'util';
  import api from 'api'
  import appConstant from '@/common/constants/app.constant';
  import { hotkeyHandler } from '@/mixins';

  let readMailInterval, unreadCountInterval, checkLogoutInterval, announcementInterval;
  export default {
    name: 'app',
    data() {
      return {
        isRouterAlive: true,
        showNotiBanner: true,
        logoutWarning: false,
        handledDraftWindows: false,
        announcement: ''
      };
    },
    computed: {
      ...mapState(["online"]),
      ...mapGetters([
        'userInfo',
        'setting_mails',
        'setting_company',
        'inappMailState'
      ]),
    },
    mixins: [hotkeyHandler],
    methods: {
      ...mapActions([
        'composeMail',
        'getTags', 
        'unlockDraft',
        'deleteDraft',
      ]),
      changeTitle(title) {
        if (title) {
          document.title = `${title}`;
        }
      },
      reload () {
        this.isRouterAlive = false;
        this.$nextTick(() => (this.isRouterAlive = true))
      },
      configMessage() {
        this.$Message.config({
          duration: 3
        });
      },
      handleHotkey(e) {
        if(e.key == 'n' || (e.key == 'n' && (e.ctrlKey || e.metaKey))) {
          e.preventDefault();
          this.composeMail({inNewWindow: this.composeInNewWindow});
        }
      },
      discardSaveDraftBeforeLeaving() {
        this.logoutAfterHandlingDraft(false);
      },
      saveDraftBeforeLeaving() {
        this.logoutAfterHandlingDraft(true);
      },
      logoutAfterHandlingDraft(saveDraft) {
        util.sendMessageToSw({ message: "closeWindow", saveDraft });
        this.logoutWarning = false;
        this.handledDraftWindows = true;
        this.$Message.loading({
          content: saveDraft ? 'Saving...' : 'Logging out...',
          duration: 0
        });

        checkLogoutInterval = setInterval(() => {
          this.$store.dispatch("Logout");
          if (/login/.test(window.location.href)) clearInterval(checkLogoutInterval);
        }, 500);
      },
      handleSocket() {
        if(!this.$ActionCable || !this.$ActionCable.subscriptions)
          return;
        let socket = this.$ActionCable.subscriptions.subscriptions[0];
        let self = this;
        socket.received.companyTag = function(tags) {
          self.$store.commit('SET_CONFIGURED_TAGS', tags);
        }
      },
      setupAnnouncementInterval() {
        if (/previewEml|sendmail|mail\/\d+/i.test(window.location.href)) return;

        this.fetchAnnouncement();
        announcementInterval = setInterval(_ => {
          this.fetchAnnouncement();
        }, 10000)
      },
      fetchAnnouncement() {
        api.fetch_announcement().then(data => {
          this.announcement = data && data.data || '';
        }).catch(_ => {
          this.announcement = '';
        });
      },
      onVisibilityChanged() {
        if(document.hidden)
          clearInterval(announcementInterval);
        else
          this.setupAnnouncementInterval();
      },
      handleCloseMaintenanceNotice() {
        this.announcement = '';
        clearInterval(announcementInterval);
      },
      handlePopupClose(event) {
        if(!event || !event.detail) return
        switch(event.detail.action) {
          case 'unlock_draft':
            event.detail.id && this.unlockDraft(event.detail.id)
            break
          case 'delete_draft':
            event.detail.id && this.deleteDraft(event.detail.id)
            break
        }
      }
    },
    created() {
      setTimeout(() => {
        const token = util.getToken();
        if(typeof(token) == 'string' && !['previewEmlWindow', 'sendMailWindow'].includes(this.$route.name)) {
          this.$store.dispatch("getRoles");
          this.$store.dispatch("getCompanySettings");
          this.setting_company.limit_hashtag == '1' && this.$store.dispatch("getTags")
          this.$store.dispatch("GetTeamMembers", { active: true });
        }
      }, 1500);
      this.setupAnnouncementInterval();
      util.changeTheme(this.setting_mails.theme_colour || appConstant.themeNames.ORIGINAL);
      util.functionBus.showLogoutWarning = () => {
        if (this.handledDraftWindows) return;
        this.logoutWarning = true;
      }
    },
    mounted() {
      this.handleSocket();
      this.configMessage();
      window.addEventListener("offline", () => {
        util.sendMessageToSw({
          message: "checkOnlineStatus",
        });
      });
      window.addEventListener("popupbeforeunload", this.handlePopupClose)
      readMailInterval = setInterval(() => {
        this.online && this.$store.dispatch("readMultipleEmails", { retry: true });
      }, 5*1000);
      unreadCountInterval = setInterval(() => {
        this.online && this.$store.dispatch("loadLines");
      }, 1000*60*60*6); // 6 hours
    },
    beforeDestroy() {
      clearInterval(readMailInterval);
      clearInterval(unreadCountInterval);
      clearInterval(announcementInterval);
    },
  };
</script>

<style lang="scss">
  @import "../public/static/style/public.scss";
  @import "./common/scss/common";
  @import "./common/iconfont_v2/style.css";

  .notice {
    position: relative;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    background-color: var(--background-color);
    color: var(--primary-color);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin-right: 5px;
      font-size: 16px;
    }
    a {
      padding: 0 5px;
      text-decoration: underline;
    }
  }
  .maintenance-notice {
    background-color: red;
    color: #fff;
    position: relative;
    padding: 0 20px;
    height: 20px;
    line-height: 20px;

    i {
      position: absolute;
      right: 10px;
      padding: 3px;
      border-radius: 50%;
      font-size: 10px;
      &:hover {
        background-color: rgba(0,0,0,.2);
        cursor: pointer;
      }
    }
  }
  .hide-overflow {
    overflow: hidden;
  }
</style>
